import './App.css';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Main from './components/main/Main';
import NotFound from "./components/main/NotFound";  // Your custom 404 component
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        
        {/* Wildcard route for non-existing URLs */}
        <Route path="*" element={<NotFound />} />
      </Routes>

    </Router>
    // <div className="App">
    //   <Main />

    //           {/* Wildcard route for non-existing URLs */}
    //           <Route path="*" element={<NotFound />} />
    // </div>
  );
}

export default App;
