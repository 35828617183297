import React, { useState } from 'react';
import './Main.css';

export default function NotFound() {
  return (
    <div>
        <h1>Странница не найдено</h1>
    </div>
  );
}
